import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import EmployeeAttendanceReport from "./EmployeeAttendanceReport";
import Excelldownload from "./Excelldownload";
import { DatePicker, Select, TreeSelect, Col, Space, Row, Button, Divider } from "antd";
import moment from "moment";
import ReactToPrint from "react-to-print";
import PdfDownload from "./PdfDownload";
import { getAllLocation } from "../../../actions/warehouseAction";
import {
  syncAttendenceByDate,
  syncAbsentByDate,
} from "../../../actions/AttendenceAction";

const Maincontent = ({
  RoleList,
  businessprofile,
  employeeList,
  getAllLocation,
  locationList,
  auth,
  syncAbsentByDate,
  syncAttendenceByDate,
}) => {
  const [updatelist, setUpdatelist] = useState(true);
  const [sync, setSync] = useState(false);
  const dateFormat = "YYYY-MM-DD";
  var date = new Date();
  var todayDate = moment(date).format(dateFormat);
  const { Option } = Select;
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());
  const [startDate, setStartDate] = useState(moment().format(dateFormat));
  const [endDate, setEndDate] = useState(moment().format(dateFormat));
  const [employee, setEmployee] = useState();
  const [location, setlocation] = useState(auth.profile.Office);
  const [data, setData] = useState([]);
  const componentRef = useRef();

  useEffect(() => {
    getAllLocation();
  }, []);

  const onMonthChange = (value, dateString) => {
    const myArr = dateString.split("-");
    let year = myArr[0];
    let mnth = myArr[1];
    setYear(year);
    setMonth(mnth);
  };
  const onStartDateChange = (value, dateString) => {
    setStartDate(dateString);
  };
  const onEndDateChange = (value, dateString) => {
    setEndDate(dateString);
  };
  const onEmployeeChange = (value) => {
    setEmployee(value);
  };
  const onLocationChange = (value) => {
    setlocation(value);
  };

  const syncdata = () => {
    setSync(true)
    syncAttendenceByDate(startDate, startDate, "1,2,3,4").then((e) => {
      syncAbsentByDate(startDate, startDate, "1,2,3,4").then((e) => {
        setSync(false)
        setUpdatelist(!updatelist)
      })
    })
  }



  return (
    <>

      <Row wrap={false}>


        <Col flex="auto">
          Date :
          <DatePicker
            style={{ width: "100%" }}
            defaultValue={moment()}
            onChange={onStartDateChange}
            format={dateFormat}
          />
        </Col>
        <Col flex="auto">.<Button disabled={sync} style={{ width: "100%" }} onClick={syncdata} >{sync ? "Syncing data...." : "Sync data"}</Button></Col>

        <Col flex="auto">
          .
          <Excelldownload
            data={data}
            data1={data}
            startDate={startDate}
            endDate={endDate}
          />
        </Col>
        <Col flex="auto">
          .
          <ReactToPrint
            trigger={() => (
              <Button style={{ width: "100%" }} type="primary">
                Print this out!
              </Button>
            )}
            content={() => componentRef.current}
          />

        </Col>
      </Row>

      <div className="site-layout-background main-frame">
        <EmployeeAttendanceReport
          startDate={startDate}
          componentRef={componentRef}
          endDate={endDate}
          employee={employee}
          location={location}
          data={data}
          setData={setData}
          updatelist={updatelist}
          setUpdatelist={setUpdatelist}

        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    employeeList: state.employee.employeelist,
    businessprofile: state.settings.businessprofile,
    locationList: state.warehouse.locationlist,
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { getAllLocation, syncAbsentByDate, syncAttendenceByDate })(Maincontent);
